body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.site-layout-content {
  flex: 1;
  padding-top: 4px; /* 为固定的 header 留出空间 */
}

.hero-section {
  min-height: calc(100vh - 4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.reveal-container {
  position: relative;
  z-index: 0; /* 确保 RevealJS 在文字之下 */
}

.reveal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reveal .slides section {
  top: 0 !important;
}

.reveal .slides section img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.hero-text {
  text-align: center;
  margin-bottom: 20px;
  z-index: 1; /* 确保文字在背景之上 */
}

.hero-text h1.ant-typography {
  margin-bottom: 20px;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #1890ff !important;
  border-bottom: 2px solid #1890ff !important;
}

.ant-menu-horizontal {
  line-height: 63px;
}

.ant-menu-item .anticon {
  font-size: 18px;
}
