@media screen and (max-width: 768px) {
    .desktop-menu {
        display: none !important;
    }

    .menu-button {
        display: block !important;
    }

    .ant-layout-header {
        padding: 0 15px !important;
    }

    .ant-layout-content {
        padding: 0 15px !important;
    }

    h1.ant-typography {
        font-size: 1.8rem !important;
    }

    h2.ant-typography {
        font-size: 1.5rem !important;
    }

    h3.ant-typography {
        font-size: 1.2rem !important;
    }

    .ant-typography {
        font-size: 1rem !important;
    }

    .ant-card-head-title {
        font-size: 1.2rem !important;
    }

    .ant-card-body {
        padding: 15px !important;
    }

    .reveal {
        max-height: 50vh !important; /* 在移动设备上限制最大高度 */
    }

    .reveal .slides section {
        padding: 10px !important; /* 减少幻灯片的内边距 */
    }

    .reveal .slides img {
        max-height: 40vh !important; /* 限制图片的最大高度 */
    }
}

@media screen and (min-width: 769px) {
    .menu-button {
        display: none !important;
    }

    .desktop-menu {
        display: flex !important;
    }
}
